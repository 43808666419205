import React from "react";
import ProductTemplate from "./product";
import { FiArrowRightCircle } from "react-icons/fi";
import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item} counter={4}>
            {/** ¿Qué es? */}
            <div className="container lg:px-0 py-20">
                <Title>El Libro</Title>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 mt-10 h-full">
                    <div className="rounded overflow-hidden shadow-md h-48 sm:h-auto">
                        <Image
                            filename="products/1/image15"
                            className="h-48 sm:h-full bg-cover bg-center"
                        >
                            {" "}
                        </Image>
                    </div>

                    <Text className="text-justify">
                        <strong>Método A</strong>, es el libro que nace de una forma distinta de
                        encarar la prueba de cultura general de las oposiciones a Policía Local.
                        <br />
                        <br />
                        Cuatro años de trabajo, resumidos en un libro.
                        <ul className="ml-0 mb mt-5">
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Las quinientas preguntas que más se han repetido en los exámenes
                                oficiales de los últimos cuatro años.
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Una única respuesta a cada pregunta.
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Ampliación de información en las preguntas más relevantes.
                            </li>
                            <li>
                                <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                                Clasificado por temáticas, para que puedas centrarte en tus puntos
                                débiles.
                            </li>
                        </ul>
                    </Text>
                </div>
                <Text className="mt-10 text-gray-600">
                    <strong>Juntamente con el libro</strong> se ofrece acceso, durante un año, a su
                    módulo en la aplicación{" "}
                    <Link
                        to="/aplicaciones/oposiciones-guardia-urbana-de-barcelona/igub/"
                        className="default-link"
                    >
                        iGuB
                    </Link>
                    . Podrás disfrutar de todo el contenido del libro donde quiera que estés, en un
                    formato cómodo y ágil.
                    <br />
                    <br />
                    ¡Ya disponible la <strong>Tercera Edición</strong>! No te quedes sin él.
                </Text>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/RN7Mx10bA-o"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    ></iframe>
                </div>
            </div>

            {/** El autor */}
            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20">
                    <Title>El autor</Title>
                    <Text className="text-justify">
                        Me llamo Alberto Guisado, tengo 37 años y soy policía.
                        <br />
                        <br />
                        En primer lugar, quisiera aclarar que este no es un libro para vender humo.
                        Nadie te va a regalar nada ni hay fórmulas mágicas para sacar un 10 sin
                        esforzarte. Simplemente es el método de estudio con el que yo me preparé
                        partiendo de mi nivel, que era más bien bajo, y con el que conseguí aprobar
                        tres oposiciones en un mismo año.
                        <br />
                        <br />
                        Tras dar un salto al vacío y dejar mi trabajo para dedicarme de pleno a
                        opositar, el primer obstáculo con el que me encontré fue que hacía muchos
                        años que no estudiaba. Apenas recordaba nada de cultura general, jamás fui
                        buen estudiante. No sabía ni por dónde empezar. Me inscribí en una academia,
                        donde me dieron mucha información, pero a pesar de que te proporcionan un
                        temario estupendo, con la cultura general era más complicado. No era
                        suficiente.
                        <br />
                        <br />
                        Por el camino me iba apuntando a muchas oposiciones, y sin darme cuenta fui
                        recabando muchas preguntas de examen. Al salir de cada prueba escrita,
                        anotaba las que había conseguido memorizar y luego las pasaba a Word.
                        <br />
                        <br />
                        Al final, gracias a todo ese esfuerzo, todas esas horas de trabajo y la
                        colaboración de muchos amigos, conseguí compilar un gran archivo y, al mismo
                        tiempo, casi sin darme cuenta, había descubierto un método de estudio muy
                        eficaz.
                        <br />
                        <br />
                        El libro que tienes en las manos está confeccionado con todas esas
                        preguntas, escogidas cuidadosamente y ordenadas para que tu nivel de cultura
                        general aumente de forma exponencial. Son preguntas de exámenes oficiales,
                        extraídas de más de doscientos municipios a lo largo de los 4 últimos años.
                        <br />
                        <br />
                        Por experiencia propia puedo asegurar que muchas preguntas van repitiéndose
                        en distintos exámenes, y que otras tantas siguen un mismo patrón. Por eso
                        puedo asegurarte que mi método, el Método A, funciona.
                        <br />
                        <br />
                        No dudes de ti. Si yo pude, tú también puedes.
                    </Text>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 mt-10">
                        <Image
                            filename="products/1/image10"
                            className="rounded shadow"
                            alt="alberto guisado"
                        />
                        <Image
                            filename="products/1/image1"
                            className="rounded shadow"
                            alt="alberto guisado método a"
                        />
                    </div>
                </div>
            </div>

            {/** Más imágenes */}
            <div className="container lg:px-0 py-20">
                <Title>Imágenes del libro</Title>
                <div className="mt-10 grid grid-cols2 md:grid-cols-3 gap-10">
                    <Image
                        filename="products/1/image4"
                        className="rounded shadow"
                        alt="método imagen 1"
                    />
                    <Image
                        filename="products/1/image5"
                        className="rounded shadow"
                        alt="método imagen 2"
                    />
                    <Image
                        filename="products/1/image6"
                        className="rounded shadow"
                        alt="método imagen 3"
                    />
                    <Image
                        filename="products/1/image8"
                        className="rounded shadow"
                        alt="método imagen 4"
                    />
                    <Image
                        filename="products/1/image9"
                        className="rounded shadow"
                        alt="método imagen 5"
                    />
                    <Image
                        filename="products/1/image2"
                        className="rounded shadow"
                        alt="método imagen 6"
                    />

                    <Image
                        filename="products/1/image14"
                        className="rounded shadow"
                        alt="método imagen 6"
                    />
                </div>
            </div>

            {/** Cómo adquirirlo */}
            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20 ">
                    <Title>¿Cómo puedo adquirirlo?</Title>
                    <Text className="text-justify">
                        Para adquirir el libro lo puedes hacer desde nuestra sección{" "}
                        <Link to="/tienda" className="default-link">
                            Tienda
                        </Link>{" "}
                        haciendo clic en el <strong>botón de añadir a la cesta</strong>.
                        <br />
                        <br />
                        Una vez recibamos la orden de compra procederemos al envío del libro. El
                        envío se realiza a través de la empresa GLS en la modalidad de envío
                        certificado. La empresa GLS os enviará un mail para que podáis saber en todo
                        momento donde se encuentra vuestro libro y si es necesario os llamarán por
                        teléfono para contactar con vosotros. El tiempo en ser entregado es
                        aproximadamente 48 horas.
                        <div className="text-left text-xs font-montserrat italic mt-10">
                            * Nos comprometemos en realizar el envío el día siguiente de realizar el
                            pago aunque no podemos hacernos responsables de los posibles retrasos
                            que la empresa transportista pueda ocasionar.
                        </div>
                    </Text>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
